.login-container {
    height: calc(100vh - 40px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-composite-icon {
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
}

.login-panel {
    display: flex;
    flex-direction: column;
    padding-left: 100px;
    padding-top: 200px;

    font-style: italic;
    font-weight: 300;
    font-size: 17px;
    line-height: 20px;
}

.login-panel-span {
    text-align: end;
}