.number-people-container {
    width: 340px;
    height: 340px;
    border-radius: 6px;

    padding-left: 26px;
    padding-right: 26px;
    position: fixed;
    background: white;
    z-index: 1;
}

.number-people-modal {
    height: 240px;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;

    border: 1px solid #D9D9D9;
    box-shadow: 4px 6px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    font-style: normal;
    font-weight: 400;
    font-size: 0.78rem;
    line-height: 15px;
}

.number-people-modal-chooser {
    position: absolute;
    height: 45px;
    width: 80%;
    left: 0;
    margin-left: 10%;
    margin-right: 10%;

    margin-top: 100px;
    background: #BFCAD6;
    box-shadow: 4px 6px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
}

.number-people-modal-title {
    text-transform: uppercase;
    padding: 19px 19px 13px;
}

.number-people-modal-scroller {
    height: calc(100% - 55px);
    position: relative;
    padding-top: 15px;

    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
}

.number-people-container .button-footer {
    padding: 10px 26px;
    vertical-align: middle;
}
