.booking:hover .room-pic {
    box-shadow: 6px 8px 6px rgba(0, 0, 0, 0.5);
}

.booking:active .room-pic {
    box-shadow: 6px 8px 6px rgba(0, 0, 0, 0.5);
}

.booking:hover .booking-text {
    color: #BB2649;
}

.booking:active .booking-text {
    color: #BB2649;
}

.booking:hover .booking-title {
    color: #BB2649;
}

.booking:active .booking-title {
    color: #BB2649;
}

.booking {
    display: inline-block;
    width: min-content;
    border: 1px solid #BB2649;
    border-radius: 7px;
    margin-right: 20px;
    margin-bottom: 16px;
}

.booking-description {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    padding-bottom: 0;
    padding-top: 0;
    padding-right: 25px;
}

.bookings .booking-title {
    padding-bottom: 0;
    line-height: 12px;
}

.booking .room-pic {
    border-radius: 0;
    box-shadow: 0 0 0;
    vertical-align: middle;
    height: 25px;
    width: auto;
    margin: 12px 6px 12px 12px;
}

.booking:hover .room-pic {
    box-shadow: 0 0;
}

.booking:active .room-pic {
    box-shadow: 0 0;
}
