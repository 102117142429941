.timeline-wrapper {
    width: 100%;
    margin-top: 8px;

}
.timeline-header, .timeline-content {
    white-space: nowrap;
}

.timeline-header {
    margin-bottom: 25px;
}







.react-calendar-timeline * {
    box-sizing: border-box; }

.react-calendar-timeline .rct-outer {
    display: block;
    overflow: hidden;
    white-space: nowrap; }

.react-calendar-timeline .rct-scroll {
    display: inline-block;
    white-space: normal;
    vertical-align: top;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-touch-action: none;
    touch-action: none; }

.react-calendar-timeline .rct-item:hover {
    z-index: 88; }

.react-calendar-timeline .rct-item .rct-item-content {
    position: sticky;
    position: -webkit-sticky;
    left: 0px;
    overflow: hidden;
    display: inline-block;
    border-radius: 1px;
    padding: 0 6px;
    height: 100%; }

.react-calendar-timeline .rct-sidebar {
    overflow: hidden;
    white-space: normal;
    display: inline-block;
    vertical-align: top;
    position: relative;
    box-sizing: border-box;
    border-right: 1px solid #bbb; }
.react-calendar-timeline .rct-sidebar.rct-sidebar-right {
    border-right: 0;
    border-left: 1px solid #bbb; }
.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
    padding: 0 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
    margin: 0;
    border-bottom: 1px solid #bbb;
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
    background: rgba(0, 0, 0, 0.05);
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even {
    background: transparent;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl {
    position: absolute;
    border-left: 1px solid #bbb;
    z-index: 30;
}
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-vl-first {
    border-left-width: 2px;
}
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6, .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
    background: rgba(250, 246, 225, 0.5);
}

.react-calendar-timeline .rct-horizontal-lines {
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    user-select: none;
}
.react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    border-bottom: 1px solid #bbb;
    box-sizing: border-box;
    z-index: 40;
}
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    background: rgba(0, 0, 0, 0.05); }
.react-calendar-timeline .rct-horizontal-lines .rct-hl-even {
    background: transparent; }

.react-calendar-timeline .rct-cursor-line {
    position: absolute;
    width: 2px;
    background: #2196f3;
    z-index: 51; }

.react-calendar-timeline .rct-dateHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: default;
    font-size: 12px;
    font-weight: 400;
    background-color: #BFCAD6;
    z-index:0;
    border-top: 1px solid #929397;
    border-bottom: 1px solid #929397;
    box-shadow: 4px 6px 4px rgba(0, 0, 0, 0.25);
}

.react-calendar-timeline .rct-dateHeader::after {
    content:"";
    position:absolute;
    z-index:-1;
    background: #BFCAD6;
    top:0;
    left:0;
    right:0;
    bottom:0;
    clip-path: polygon(90% 0, 100% 0, 100% 50%, 100% 100%, 90% 100%, 100% 50%);
}

.react-calendar-timeline .rct-dateHeader::before {
    content:"";
    position:absolute;
    z-index:-1;
    background: #929397;
    top:0;
    left:0;
    right:0;
    bottom:0;
    border-top: 1px solid #929397;
    border-bottom: 1px solid #929397;
    clip-path: polygon(88% 0, 100% 0, 100% 50%, 100% 100%, 88% 100%, 98% 50%);
}


.react-calendar-timeline .rct-dateHeader:nth-child(22) {
    box-shadow: 4px 6px 4px rgba(0, 0, 0, 0.25);
}

.react-calendar-timeline .rct-dateHeader:nth-child(11) {
    box-shadow: none;
}

.react-calendar-timeline .rct-dateHeader:nth-child(12) {
    border-left: 1px solid #929397;
}

.react-calendar-timeline .rct-dateHeader-primary {
    background-color: initial;
    border-left: 1px solid #bbb;
    border-right: 1px solid #bbb;
    color: #fff;
}

.react-calendar-timeline .rct-header-root {
    border-bottom: 1px solid #bbb;
}

.react-calendar-timeline .rct-calendar-header {
    /*border-left: 1px solid #bbb;*/
    /*border-right: 1px solid #bbb;*/
}

