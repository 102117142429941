.office-container {
    height: 100%;
    width: 100%;
}

.office-container .office-list {
    height: 100%;
    padding: 25px;
    overflow-y: auto;
}

.office-container .city-office-content {
    width: fit-content;
}

.office-container .city-content {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;

}

.office-container .office-content {
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-transform: none;
    padding: 10px 20px;
}

.office-container .office-active {
    margin-left: 10px;
    color: #BB2649;
}
