.info-table {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 7px;
}

.info-table thead {
    text-align: left;
}

.info-table thead th:first-child {
    border-radius: 7px 0 0 0;
}

.info-table thead th:last-child {
    border-radius: 0 7px 0 0;
}

.info-table tbody td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    text-align: center;
}

.info-table thead th:first-child, .info-table tbody td:first-child {
    padding-left: 40px;
}

.info-table tbody tr:first-child td {
    padding-top: 30px;
}

.info-table tbody tr:last-child td {
    padding-bottom: 30px;
    border-bottom: none;
}

.info-table thead th {
    padding: 11px 15px;
    cursor: pointer;
    text-align: center;

    background-color: #D4CACD;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #000000;
}

.sorting-desc {
    border-bottom: 2px solid #5d0317;
}

.sorting-asc {
    border-top: 2px solid #5d0317;
}

.info-table tbody td {
    padding: 11px 15px;

    font-weight: 400;
    font-size: 13px;
    color: #000000;
}

.table-booking-row-nowrap {
    white-space: nowrap;
}

.table-booking-delete {
    font-style: italic;
    font-size: 10px !important;
    cursor: pointer;

    color: #BB2649 !important;
}

.table-booking-delete:hover {
    color: #5d0317 !important;
    text-decoration: underline;
}

.table-row-click {
    cursor: pointer;    
}

.table-row-click:hover {
    text-decoration: underline;
}

.table-pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    padding-top: 10px;
    padding-bottom: 30px;
    padding-right: 30px;

    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
}

.table-pagination-pages {
    margin-left: 20px;
    display: flex;
    align-items: center;
}

.table-pagination .Dropdown-menu {
    display: block;
    position: absolute;
    bottom: 25px;
    top: auto;
}

.table-pagination .Dropdown-control {
    height: 25px;
    padding: 5px 25px 8px 10px;
    border: none;
}

.table-pagination .Dropdown-arrow {
    right: 10px;
    top: 10px;
}

.table-pagination-pages div {
    cursor: pointer;
    margin-right: 10px;
}

.pagination-page-count {
    margin-left: 5px;
}

.even-stroke:nth-child(even) {
    background-repeat: no-repeat;
    background-position: center bottom 1px;
    background-size: 100% 0.085rem;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.4), #BB2649);
}

.even-stroke:last-child {
    background: none;
}

