.bread-crumbs {
    margin-left: 110px;

    font-size: 12px;
    text-transform: uppercase;
    color: #7B7E7A;
}

.crumb {
    font-weight: 600;
    color: #BB2649;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

.crumb:hover {
    text-decoration: underline;
}

.crumbs-separator {
    font-size: 16px;
}
