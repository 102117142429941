.meeting-rooms-management-screen {
    height: 100%;
}

.meeting-rooms-management-main-wrapper {
    margin-top: 30px;
    margin-left: 110px;
    margin-right: 200px;
}

.meeting-rooms-management-rooms-list {
    max-height: calc(100vh - 220px);
    overflow-y: auto;
}