.edit-chooser-panel {
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-shadow: 4px 6px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
}

.chooser-panel-wrapper {
    margin: 25px 80px;
}

.edit-chooser-panel-exit {
    display: flex;
    margin: 20px 40px 0 auto;
    cursor: pointer;
}

.edit-booking-office-wrapper {
    z-index: 15;
    position: fixed;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-shadow: 4px 6px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
}