.header {
    height: 150px;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.header-right-wrapper {
    margin-left: auto;
    margin-right: 40px;
}

.header-name {
    display: flex;
    align-items: center;

    margin-left: 110px;
    margin-top: 33px;

    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #BB2649;
}

.header-loc {
    cursor: pointer;

    margin-top: 40px;

    font-weight: 400;
    font-size: 13px;
    line-height: 16px;

    color: #000000;

    text-align: right;
}

.header-search::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
}

.header-search:hover {
    width: 504px;
}

.header-search:focus{
    width: 504px;
    outline: none;
}

.header-search {
    font-size: 12px;

    background-image: url('/public/img/icons/search.svg');
    background-repeat: no-repeat;
    background-position: right 15px top 50%;

    text-align: right;
    padding-right: 45px;

    margin-top: 30px;
    width: 301px;
    height: 37px;

    border: 1px solid #000000;
    border-radius: 7px;

    transition: width 500ms ease-out;
}

.sign-out-btn {
    cursor: pointer;
    margin-bottom: 55px;
    margin-right: 25px;
}

.add-button-wrapper {
    width: 185px;

    right: 0;
    top: 0;
    margin-top: 110px;
    margin-right: 30px;

    position: fixed;
    background: #FFFFFF;
    z-index: 1;
}

.rc-switch {
    margin-left: 15px;
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    width: 55px;
    height: 30px;
    padding: 0;
    vertical-align: middle;
    border: 1px solid #272525;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    overflow: hidden;
}
.rc-switch-inner-checked,
.rc-switch-inner-unchecked {
    color: #fff;
    font-size: 12px;
    position: absolute;
    top: 0;
    transition: left 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
.rc-switch-inner-checked {
    left: -14px;
}
.rc-switch-inner-unchecked {
    left: 24px;
}
.rc-switch:after {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 2px;
    top: 1.40px;
    border-radius: 3px;
    background-color: #000000;
    content: " ";
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
    transform: scale(1);
    transition: left 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
    animation-duration: 0.3s;
    animation-name: rcSwitchOff;
}
.rc-switch:hover:after {
    transform: scale(1.1);
    animation-name: rcSwitchOn;
}
.rc-switch:focus {
    box-shadow: 0 0 0 2px #d5f1fd;
    outline: none;
}
.rc-switch-checked {
    background-color: #E4A8B6;
}
.rc-switch-checked .rc-switch-inner-checked {
    left: 6px;
}
.rc-switch-checked .rc-switch-inner-unchecked {
    left: 44px;
}
.rc-switch-checked:after {
    left: 27px;
}
.rc-switch-disabled {
    cursor: no-drop;
    background: #ccc;
    border-color: #ccc;
}
.rc-switch-disabled:after {
    background: #9e9e9e;
    animation-name: none;
    cursor: no-drop;
}
.rc-switch-disabled:hover:after {
    transform: scale(1);
    animation-name: none;
}
.rc-switch-label {
    display: inline-block;
    line-height: 20px;
    font-size: 14px;
    padding-left: 10px;
    vertical-align: middle;
    white-space: normal;
    pointer-events: none;
    user-select: text;
}
@keyframes rcSwitchOn {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.25);
    }
    100% {
        transform: scale(1.1);
    }
}
@keyframes rcSwitchOff {
    0% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
