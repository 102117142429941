.dropdown-time-container {
    position: fixed;
    background: white;
    z-index: 1;
    height: 400px;
    width: 550px;
    border-radius: 6px;
    padding-left: 30px;
    padding-right: 30px;
}

.dropdown-time-picker-exit {
    display: flex;
    margin: 18px 0 0 auto;
    cursor: pointer;
}

.dropdown-time-title {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;
    color: #7B7E7A;
}

.dropdown-time-wrapper {
    display: flex;
    margin-top: 40px;

    width: 100%;
    height: 120px;
    margin-bottom: 10px;
    border: 1px solid #D9D9D9;
    box-shadow: 4px 6px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    font-style: normal;
    font-weight: 400;
    font-size: 0.78rem;
    line-height: 15px;
}

.dropdown-time-start-wrapper {
    width: 50%;
}

.dropdown-time-end-wrapper {
    width: 50%;
}

.dropdown-dropdown-wrapper {
    margin: 0 30px;
}

.dropdown-button-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 80px 60px 0 60px;
}

.time-picker-modal-title {
    text-align: center;
}

.time-change-view {
    width: 100px;
    height: 30px;
    background: #BB2649;
    cursor: pointer;
    border: none;
    border-radius: 6px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: white;
    margin-left: 50px;
    position: absolute;
    right: 28px;
    top: 18px;
}