.schedule-container{
    width: 70%;
    background-color: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-shadow: 4px 6px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    max-height: 80%;
    overflow-y: auto;
}

.schedule-title {
    display: flex;
    text-transform: uppercase;
    margin: 30px;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
}

.schedule-table-wrapper {
    margin: 20px 40px;
}

.schedule-exit {
    display: flex;
    margin-left: auto;
    cursor: pointer;
}

.schedule-button {
    width: 270px;
    margin-left: auto;
    margin-right: auto;
}

.schedule-buttons-wrapper {
    width: 300px;
    margin: 0 auto;
}

.schedule-changing-container {
    width: 500px;
    background-color: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-shadow: 4px 6px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
}