.button {
    width: 90%;
    height: 37px;
    background: #BB2649;
    cursor: pointer;
    border: none;
    border-radius: 6px;
    display: block;
    margin: 14px auto;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: white;
}

.button:hover {
    border: 1px solid #BB2649;
    background: white;
    color: #BB2649;
}

.button:active {
    background: #961936;
    color: white;
}

.button.black {
    background: #272525;
}

.button.black:hover {
    background: #FFFFFF;
    color: #272525;
    border-color: #272525;
}

.button.black:active {
    background: #7B7E7A;
    color: #FFFFFF;
    border-color: #7B7E7A;
}

.button.white {
    background: #FFFFFF;
    border: 1px solid #535459;
    color: #535459;
}

.button.white:hover {
    background: #535459;
    color: #FFFFFF;
}

.button.white:active {
    background: #7B7E7A;
    color: #FFFFFF;
    border-color: #7B7E7A;
}
