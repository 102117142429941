.party-adding-screen {
    height: 100%;
}

.party-adding-main-wrapper {
    margin-top: 30px;
    margin-left: 110px;
}

.party-adding-title {
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    text-transform: uppercase;
    color: #000000;

    margin-top: 15px;
    margin-bottom: 40px;
}

.party-adding-subtitle {
    white-space: nowrap;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;
    color: #7B7E7A;

    margin-bottom: 20px;
}

.party-adding-content-wrapper {
    overflow-x: auto;
    display: flex;
}

.party-adding-chooser-panel {
    flex-shrink: 0;
    width: 360px;
    margin-right: 100px;
}

.party-adding-rooms-wrapper {
    display: flex;
}

.party-adding-rooms {
    flex: 1;
    margin-right: 30px;
}

.party-adding-rooms-list {
    height: 360px;
    border: 1px solid #000000;
    border-radius: 8px;
    overflow-y: auto;
}

.party-adding-rooms-list-element {
    height: 48px;
    display: flex;
    align-items: center;
    margin-left: 18px;
    margin-right: 18px;
    margin-bottom: 8px;
}

.party-adding-rooms-list-element:first-child {
    margin-top: 18px;
}

.party-adding-rooms-list-title-box {
    display: flex;
    flex: 1;
    height: 100%;
    box-shadow: 4px 6px 4px rgba(0, 0, 0, 0.25);
    background: rgba(187, 38, 73, 0.15);
    border-radius: 8px;
    margin-right: 18px;
    justify-content: center;
    align-items: center;
}

.pa-gray {
    background: rgba(191, 202, 214, 0.4);
}

.party-adding-rooms-list-title {
    margin: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;

    color: #000000;
}

.party-adding-rooms-list-button {
    cursor: pointer;
    display: flex;
    height: 40px;
    width: 40px;
    background: #ECECEC;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 20px;
}

.party-adding-schedule-date {
    display: flex;
    flex-direction: column;
}
.party-adding-schedule-date input {

}

.party-adding-schedule-checker {
    display: block;
    cursor: pointer;
    padding-top: 40px;
}

.party-adding-checkers {
    display: block;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
}

.party-adding-radio-panel {
    display: inline-block;
    vertical-align: top;
}

.party-adding-checker {
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    margin-left: 50px;
}

.checkbox {
    accent-color: #BB2649;
    margin-right: 6px;
}

.radio {
    accent-color: #BB2649;
    margin-right: 6px;
}

.party-adding-buttons-footer {
    width: 300px;
    margin-left: auto;
    margin-right: 12px;
    margin-top: 100px;
}