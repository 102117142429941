.my-booking-description-screen {
    height: 100%;
    overflow-x: hidden;
}

.my-booking-description-wrapper {
    display: flex;
}

.my-booking-description-container {
    margin-left: 110px;
    height: 100%;
    width: 360px;
}

.my-booking-timeline-container {
    display: flex;
    width: 100%;
    margin-left: 65px;
    margin-top: 100px;
}

.edit-chooser-panel {
    width: 535px;
    height: 465px;
    background: white;
}

.my-booking-description-title {
    margin: 20px 0;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    text-transform: uppercase;

    color: #000000;
}

.booking-header {
    display: flex;
    justify-content: space-between;
}

.fine-button {
    margin-top: 20px;
}

.my-booking-description-subtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;

    color: #7B7E7A;
}

.my-booking-description-subtitle-time {
    padding-left: 10px;

    font-style: italic;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;

    color: #7B7E7A;
}

.my-booking-description-text {
    font-style: italic;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;

    color: #000000;
}

.my-booking-description-text td {
    padding-right: 11px;
    padding-bottom: 5px;
}

.my-booking-description-text > div {
    padding-top: 6px;
    padding-bottom: 32px;
    text-transform: none;
}

.buttons-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.buttons-footer .button {
    margin-bottom: 5px;
    margin-top: 5px;
}
