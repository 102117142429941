.booking-room-registry-container{
    width: 70%;
    background-color: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-shadow: 4px 6px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    overflow-y: auto;
    max-height: 80%;
}

.booking-room-registry-title {
    display: flex;
    text-transform: uppercase;
    margin: 30px;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
}

.booking-room-registry-table-wrapper {
    margin: 20px 40px;
}

.booking-room-registry-exit {
    display: flex;
    margin-left: auto;
    cursor: pointer;
}
