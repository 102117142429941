
.text-field_floating {
    position: relative;
    margin: 1rem 0;
    height: 100%;
}

.text-field_floating .text-field__input {
    height: 100%;
    line-height: 1.25;
    margin-top: 1.625rem;
    margin-bottom: 0.425rem;
    padding-bottom: .1rem;
    border: none;
    outline: none;
    resize: none;
}

.text-field {
    height: 100%;
    overflow-y: auto;
    color: #000;
    background-color: #ECECEC;
    border-radius: 8px;
}

.text-field__input::placeholder {
    font-family: 'Ubuntu', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
}

.text-field__input {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0 2.75rem 0 3.3rem;
    color: #000;
    background-color: #ECECEC;

    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
}

.text-field_floating .text-field__label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 0.75rem 2.75rem 0.75rem 3.3rem;
    pointer-events: none;

    font-weight: 300;
    font-size: 9px;
    line-height: 11px;

    color: #000;
}

.text-field__icon {
    position: absolute;
    height: 25px;
    width: 25px;
    margin-top: 12px;
    margin-left: 15px;
    top: 0;
    left: 0;
}