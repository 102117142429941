.booking-management-screen {
    height: 100%;
}



.booking-management-main-wrapper {
    margin-top: 30px;
    margin-left: 110px;
    margin-right: 200px;
}

.booking-management-date-picker {
    display: flex;
    justify-content: flex-end;
    font-style: italic;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
}

.booking-management-date-picker svg {
    cursor: pointer;
    margin-left: 5px;
}
