
.weekdays {
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background: rgba(217, 217, 217, 0.5);
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 20px;

}


.weekday {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #7B7E7A;

}