.reservation-screen {
    height: 100%;
}

.reservation-main-wrapper {
    margin-left: 110px;
    height: 100%;
    overflow-y: auto;
}

.reservation-content-wrapper {
    display: flex;
    height: 100%;
    margin-top: 40px;
}

.reservation-office-picker-wrapper {
    width: 275px;

    right: 0;
    top: 0;
    margin-top: 80px;
    margin-right: 70px;

    position: fixed;
    background: #FFFFFF;
    border: 1px solid #BFCAD6;
    border-radius: 7px;
    z-index: 90;

    transition: all .5s ease-in-out;
}

.reservation-selectors {
    margin-right: 40px;
}

.reservation-chooser-panel {
    width: 360px;
}

.chooser-panel-title {
    margin-bottom: 25px;

    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;

    color: #7B7E7A;
}

.reservation-found-rooms-wrapper {
    margin-right: 200px;
}

.background {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}