.time-container {
    position: fixed;
    background: white;
    z-index: 1;
    height: 550px;
    width: 400px;
    border-radius: 6px;
}

.time-picker-title {
    margin-top: 25px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;
    color: #7B7E7A;
}

.time-picker {
    display: flex;
    flex-direction: column;
    height: 85%;
    width: 100%;
    padding-left: 26px;
    padding-right: 26px;
    overflow-y: auto;
}

.time-picker-buttons-panel {
    display: flex;
    flex: 2;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 47px;
}

.time-picker-button-start, .time-picker-button-finish {
    cursor: pointer;
    display: inline-block;
}

.active-button {
    color: #BB2649;
}

.time-picker-modal {
    height: 240px;
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid #D9D9D9;
    box-shadow: 4px 6px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    font-style: normal;
    font-weight: 400;
    font-size: 0.78rem;
    line-height: 15px;
}

.time-picker-modal-title {
    text-transform: uppercase;
    padding: 19px 19px 13px;
}

.time-picker-modal-hours, .time-picker-modal-minutes {
    display: inline-block;
    height: calc(100% - 70px);
    width: 50%;
    text-align: center;
    vertical-align: top;
}


.time-picker-modal-hours-scroller, .time-picker-modal-minutes-scroller {
    height: 100%;
    position: relative;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
}

.time-picker-modal-chooser {
    position: absolute;
    height: 45px;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    left: 0;
    margin-top: 100px;
    background: #BFCAD6;
    box-shadow: 4px 6px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
}

.time-container .button-footer {
    display: flex;
    align-items: center;
    height: 15%;
    padding: 0 26px;
}
