.found-rooms-content {
    height: 100%;
    width: 100%;
    overflow-y: auto;
}

.found-rooms-relevant {
    padding-bottom: 25px;
}

.found-rooms-scroller {
}

.found-rooms-list {
    height: 100%;
    padding-left: 16px;
    padding-right: 16px;
}

.found-rooms-title {
    display: flex;
    align-items: baseline;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;

    color: #7B7E7A;
}

.found-rooms-title-time {
    padding-left: 10px;

    font-style: italic;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;

    color: #7B7E7A;
}

.found-rooms-title-date {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    font-style: italic;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;

    color: #000000;
}

.found-rooms-title-date svg {
    margin-left: 5px;
    cursor: pointer;
}

.found-rooms-subtitle {
    padding-top: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;

    color: #BB2649;
}

.hand-icon {
    position: absolute;
    animation: go-left-right 1.5s infinite alternate;
    right: 10%;
    top: 50%;
}

.booking-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

}


@keyframes go-left-right {
    from {
        transform-origin: bottom center;
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        transform-origin: bottom center;
        -ms-transform: rotate(-15deg);
        -moz-transform: rotate(-15deg);
        -webkit-transform: rotate(-15deg);
        -o-transform: rotate(-15deg);
        transform: rotate(-15deg);
    }
}