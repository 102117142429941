.booking-management-main-table {
    width: 100%;
    display: flex;
}

.booking-management-table-wrapper {
    overflow-x: auto;
    width: 50%;
}

.booking-management-timeline-wrapper {
    margin-left: 10px;
    overflow-x: auto;
    width: 50%;

}