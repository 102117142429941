.booking-list-item {
    width: 360px;
    margin-top: 18px;
    margin-right: 40px;
}

.booking-list-item-container {
    cursor: pointer;
}

.booking-list-item-pic {
    height: 190px;
    width: 100%;
    border-radius: 8px;
    box-shadow: 4px 6px 4px rgba(0, 0, 0, 0.25);
    object-fit: cover;
}

.booking-list-item-size {
    position: relative;
    margin-left: 75%;
    top: -200px;
}

.booking-list-item-size-pic {
    position: absolute;
}

.booking-list-item-size-text {
    position: absolute;
    padding-left: 12px;
    padding-top: 9px;
    font-style: normal;
    font-weight: 900;
    font-size: 8px;
    line-height: 10px;
    text-align: center;
    text-transform: uppercase;

    color: #FFFFFF;
}

.booking-list-item-title {
    margin: 12px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
}

.booking-list-item:hover .booking-list-item-pic {
    box-shadow: 6px 8px 6px rgba(0, 0, 0, 0.5);
}

.booking-list-item:active .booking-list-item-pic {
    box-shadow: 6px 8px 6px rgba(0, 0, 0, 0.5);
}

.booking-list-item:hover .booking-list-item-title {
    color: #BB2649;
}

.booking-list-item:active .booking-list-item-title {
    color: #BB2649;
}

.booking-list-panel-times-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.booking-list-times-button {
    height: 28px;
    width: 80px;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-align: center;

    color: #BB2649;

    border: 1px solid #BB2649;
    border-radius: 6px;
}

.booking-list-times-button:hover {
    color: #FFFFFF;
    background: #961936;
    border-radius: 7px;
    border: none;
}


.booking-list-item-edit-buttons {
    display: none;
    justify-content: flex-end;

    font-weight: 400;
    font-size: 10px;

}

.booking-list-item-button-edit {
    color: #535459;
}

.booking-list-item:hover .booking-list-item-edit-buttons {
    display: flex;
    margin-top: -15px;
}

.booking-list-item-button-edit:hover {
    text-decoration: underline;
}
