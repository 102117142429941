.filter-button {
    text-align: left;
    background: #ECECEC;
    border-radius: 8px;
    border: none;
    width: 100%;
    height: 45px;
    margin-bottom: 20px;
    cursor: pointer;

    display: flex;
}

.filter-button .icon-container {
    display: inline-block;
    vertical-align: top;
    height: 40px;
    width: 40px;
    margin-left: 10px;
    margin-right: 7px;
    text-align: center;

}

.filter-button img {
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 0;
}

.filter-button .filter-description {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex-shrink: 100;

    color: #7B7E7A;
    padding-top: 7px;

}

.filter-button .filter-title {
    font-style: normal;
    font-weight: 300;
    font-size: 9px;
    line-height: 11px;
}

.filter-button .filter-text {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    margin-top: 2px;
}



.filter-button:hover .filter-description.active {
    color: #BB2649;
}

.filter-button:active .filter-description.active {
    color: #535459;
}


@media (max-height: 550px) {
    .filter-button {
        margin-bottom: 12px;
    }
}
