.buttons-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.room-description-title {
    margin-top: 20px;
    margin-bottom: 40px;
    white-space: nowrap;


    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    text-transform: uppercase;

    color: #000000;
}