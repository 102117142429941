.booking-management-screen {
    height: 100%;
}

.booking-management-main-wrapper {
    margin-top: 30px;
    margin-left: 110px;
    margin-right: 110px;
}

.booking-management-date-picker {
    display: flex;
    justify-content: flex-end;
    font-style: italic;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
}

.booking-management-date-picker svg {
    cursor: pointer;
    margin-left: 5px;
}

.qr-codes-panel {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: right;
    margin-bottom: 65px;
    margin-right: 120px;
    z-index: 100;
}

@media (min-width: 600px) and (max-width: 1200px) {
    .header {
        height: 115px;
    }
    .header-logo img {
        height: 50px;
    }
    .header-name {
        margin-top: 12px;
        margin-left: 75px;
        font-size: 16px;
    }
    .header-loc {
        margin-top: 30px;
        font-size: 11px;
    }
    .booking-management-main-wrapper {
        margin-top: 0;
        margin-left: 40px;
        margin-right: 40px;
    }
    .header-search {
        margin-top: 14px;
    }
    .header-right-wrapper {
        margin-right: 25px;
    }
    .sign-out-btn {
        margin-bottom: 40px;
    }
    body {
        padding-top: 8px !important;
    }
    .info-table thead th {
        font-size: 13px;
        padding: 8px 10px;
    }
    .info-table tbody td {
        padding: 8px 10px;
        font-size: 11px;
    }
    .info-table tbody tr:first-child td {
        padding-top: 15px;
    }
    .info-table tbody tr:last-child td {
        padding-bottom: 9px;
        border-bottom: none;
    }
    .react-calendar-timeline .rct-dateHeader {
        font-size: 11px;
    }
    .rct-calendar-header div {
        margin-bottom: 9px !important;
    }
    .timeline-wrapper {
        margin-top: 3px;
    }
    .qr-codes-panel {
        margin-right: 50px;
        margin-bottom: 30px;
    }
    .qr-codes-panel img {
        width: 100px;
    }
}

@media (min-width: 1200px) and (max-width: 1500px) {
    .header {
        height: 130px;
    }
    .header-logo img {
        height: 65px;
    }
    .header-name {
        margin-top: 12px;
        margin-left: 100px;
        font-size: 17px;
    }
    .header-loc {
        margin-top: 35px;
        font-size: 12px;
    }
    .booking-management-main-wrapper {
        margin-top: 0;
        margin-left: 40px;
        margin-right: 50px;
    }
    .header-search {
        margin-top: 23px;
    }
    .header-right-wrapper {
        margin-right: 25px;
    }
    .sign-out-btn {
        margin-bottom: 43px;
        margin-right: 50px;
    }
    .info-table thead th {
        font-size: 14px;
        padding: 9px 10px;
    }
    .info-table tbody td {
        padding: 9px 10px;
        font-size: 12px;
    }
    .info-table tbody tr:first-child td {
        padding-top: 16px;
    }
    .info-table tbody tr:last-child td {
        padding-bottom: 9px;
        border-bottom: none;
    }
    .react-calendar-timeline .rct-dateHeader {
        font-size: 11px;
    }
    .rct-calendar-header div {
        margin-bottom: 9px !important;
    }
    .timeline-wrapper {
        margin-top: 5px;
    }
    .qr-codes-panel {
        margin-right: 65px;
        margin-bottom: 30px;
    }
    .qr-codes-panel img {
        width: 120px;
    }
}
