.room-editing-screen {
    height: 100%;
}

.room-editing-container {
    margin-left: 110px;
    height: 100%;
}

.room-editing-title {
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    text-transform: uppercase;
    color: #000000;

    margin-top: 15px;
    margin-bottom: 40px;
}

.room-editing-subtitle {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;
    color: #7B7E7A;

    margin-bottom: 20px;
}

.room-editing-props {
    width: 360px;
    display: inline-block;
    vertical-align: top;
}

.room-editing-photo {
    width: 360px;
    display: inline-block;
    vertical-align: top;

    margin-left: 110px;
}

.room-description-text {
    font-style: italic;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;

    color: #000000;
}

.room-description-text > div {
    padding-top: 6px;
    padding-bottom: 32px;
    text-transform: none;
}

.room-editing-button-footer {
    margin-top: 262px;
}


.room-editing-image {
    height: 190px;
    width: 100%;
    border-radius: 8px;
    object-fit: cover;
}
