
.confirmation-modal-main {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    width: 500px;
    height: 200px;

    background-color: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-shadow: 4px 6px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
}

.confirmation-modal-title {
    margin-left: 10px;
    text-align: center;
    margin-right: 10px;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    white-space: pre-line;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #000000;
}

.confirmation-modal-buttons {
    margin-left: 250px;
    margin-right: 60px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;

}

.confirmation-modal-buttons button {
    width: 35%;
}