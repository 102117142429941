.blink {
    color: #BB2649;
    animation-name: blinker;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(1.0,0,0,1.0);
    animation-duration: 2s;
    -webkit-animation-name: blinker;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: cubic-bezier(1.0,0,0,1.0);
    -webkit-animation-duration: 2s;
}

@keyframes blinker {
    from { opacity: 1.0; }
    to { opacity: 0.0; }
}

@-webkit-keyframes blinker {
    from { opacity: 1.0; }
    to { opacity: 0.0; }
}