.description-modal {
    height: 500px;
    width: 400px;

    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;

    position: fixed;
    background: white;
    z-index: 1;
    border-radius: 6px;
}

.description-container {
    height: 85%;
    width: 100%;
    padding: 26px;
    overflow-y: auto;
}

.input-panel {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
}

.description-topic {
    padding-bottom: 20px;
}

.description-head-person {
    float: left;
    width: 100%;
    position: relative;
    margin-bottom: 20px;
    background: white;
}

.description-description {
    float: left;
    width: 100%;
    position: relative;
}

.description-description-textarea {
    height: 200px;
    resize: vertical;
    border-radius: 3px;
}

.description-container .button-footer {
    height: 15%;
    padding: 0 26px;
    vertical-align: middle;
}


.Dropdown-root {
    position: relative;
}

.Dropdown-control {
    position: relative;
    overflow: hidden;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-sizing: border-box;
    color: #333;
    cursor: default;
    outline: none;
    padding: 8px 52px 8px 15px;
    transition: all 200ms ease;
}

.Dropdown-control:hover {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

.Dropdown-arrow {
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: ' ';
    display: block;
    height: 0;
    margin-top: -ceil(2.5);
    position: absolute;
    right: 10px;
    top: 14px;
    width: 0
}

.is-open .Dropdown-arrow {
    border-color: transparent transparent #999;
    border-width: 0 5px 5px;
}

.Dropdown-menu {
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 10;
    -webkit-overflow-scrolling: touch;
}

.Dropdown-menu .Dropdown-group > .Dropdown-title{
    padding: 8px 15px;
    color: rgba(51, 51, 51, 1);
    font-weight: bold;
    text-transform: capitalize;
}

.Dropdown-option {
    box-sizing: border-box;
    color: rgba(51, 51, 51, 0.8);
    cursor: pointer;
    display: block;
    padding: 8px 15px;
}

.Dropdown-option:last-child {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
}

.Dropdown-option:hover {
    background-color: #e8f3f8;
    color: #333;
}

.Dropdown-option.is-selected {
    background-color: #e8f3f8;
    color: #333;
}

.Dropdown-noresults {
    box-sizing: border-box;
    color: #ccc;
    cursor: default;
    display: block;
    padding: 8px 10px;
}

.effect-8 {
    width: 100%;
    border: 1px solid #ccc; padding: 7px 14px 9px; transition: 0.4s;
    outline: none;
    border-radius: 3px;
    background-color: white;
}

.effect-8::placeholder {
    font-family: system-ui;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
}

.effect-8 ~ .focus-border:before,
.effect-8 ~ .focus-border:after{content: ""; position: absolute; top: 0; left: 0; width: 0; height: 2px; background-color: #BB2649; transition: 0.3s;}
.effect-8 ~ .focus-border:after{top: auto; bottom: 0; left: auto; right: 0;}
.effect-8 ~ .focus-border i:before,
.effect-8 ~ .focus-border i:after{content: ""; position: absolute; top: 0; left: 0; width: 2px; height: 0; background-color: #BB2649; transition: 0.4s;}
.effect-8 ~ .focus-border i:after{left: auto; right: 0; top: auto; bottom: 0;}
.effect-8:focus ~ .focus-border:before,
.effect-8:focus ~ .focus-border:after{width: 100%; transition: 0.3s;}
.effect-8:focus ~ .focus-border i:before,
.effect-8:focus ~ .focus-border i:after{height: 100%; transition: 0.4s;}