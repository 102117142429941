.room-description-screen {
    height: 100%;
}

.room-description-container {
    width: 360px;
    margin-left: 110px;
}

.room-description-title {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;

    color: #7B7E7A;
}

.room-description-title-time {
    padding-left: 10px;

    font-style: italic;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;

    color: #7B7E7A;
}

.room-description-text {
    font-style: italic;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;

    color: #000000;
}

.room-description-text > div {
    padding-top: 6px;
    padding-bottom: 32px;
    text-transform: none;
}

.button-footer {
    display: flex;
    justify-content: center;
}

