.menu {
    background: #272525;
    border-radius: 7px;

    position: absolute;
    width: 60px;
    height: 332px;
    left: 26px;
    top: 276px;

    filter: drop-shadow(4px 6px 4px rgba(0, 0, 0, 0.25));

    z-index: 9;
}

.menu-icon-panel {
    padding-top: 15px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.menu-icon {
    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-items: center;

    height: 43px;
    margin: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

.menu-icon span {
    display:  none;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;

    color: #BB2649;

    flex-shrink: 0;
    margin-left: 17px;
}

.menu-icon:hover {
    align-self: flex-start;

    width: 178px;
    height: 43px;
    background: #BFCAD6;
    box-shadow: 4px 6px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;

    fill: #BB2649;
}

.menu-icon:hover span {
    display: flex;
}

.menu-icon:hover path {
    fill: #BB2649;

}
