.room-container {
    height: 100%;
    width: 100%;
}

.room-list {
    height: 100%;
    padding: 25px;
    overflow-y: auto;
}

.room-content {
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-transform: none;
    padding: 10px 20px;
}

.room-active {
    margin-left: 10px;
    color: #BB2649;
}
